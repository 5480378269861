import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { buttonVariants } from '~/components/ui/button'
import { Card } from '~/components/ui/card'

export const HomeIntro = () => {
	const { t } = useTranslation()

	return (
		<div className='relative flex h-[400px] flex-col items-start bg-cover bg-right-bottom p-8 text-white md:h-[600px] md:justify-center md:p-24'>
			<Image
				src='/images/home-header.jpg'
				alt=''
				fill
				className='z-0 object-cover object-[-660px_center] md:object-center'
			/>

			<div className='absolute inset-0 bg-gradient-to-br from-black/20 to-primary/30' />

			<div className='relative flex flex-col gap-6'>
				<div className='z-10 flex flex-col gap-6 font-bold text-white'>
					<Trans
						parent='div'
						i18nKey='cwHome:intro.baseline'
						className='z-10 text-3xl leading-none [text-shadow:_5px_0px_10px_rgb(0_0_0_/_40%)] md:text-[45px]'
					/>

					<Trans
						parent='div'
						i18nKey='cwHome:intro.subBaseline'
						className='text-lg [text-shadow:_5px_0px_10px_rgb(0_0_0_/_40%)] md:text-4xl'
					/>
				</div>
			</div>

			<div className='-bottom-4 -translate-x-1/2 absolute left-1/2 z-10 flex translate-y-1/2 flex-col items-center gap-4'>
				<Card className='flex flex-col items-center gap-1 px-6 py-4 md:mb-0 md:flex-row md:gap-4 md:rounded-full'>
					<Link
						href='/paris'
						className={buttonVariants({ className: 'w-[250px]' })}
					>
						{t('actions:seeCocoons.btnLabel')}
					</Link>

					{t('cwHome:intro.or')}

					<Link
						href='/desks/paris'
						className={buttonVariants({
							variant: 'outlinePrimary',
							className: 'w-[250px]',
						})}
					>
						{t('cwHome:intro.seeDesks')}
					</Link>
				</Card>

				<Trans
					parent='div'
					i18nKey='cwHome:intro.search'
					className='text-center font-normal text-foreground text-sm'
				/>
			</div>
		</div>
	)
}
